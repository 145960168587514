import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from 'react-hook-form';
import PSInputText from "components/globals/ui/input/PSInputText";
import { PSSubmit, PSWysiwyg } from "components/globals/ui/input/PSInputs";
import { prepareFormData } from "helpers/utils";
import { updateUserSetting, updateUserSettingImage } from 'store/authSlice'
import { useDispatch } from 'react-redux'
import SingleImageUploader from "components/globals/ui/image/SingleImageUploader"
import Single from "components/globals/image/Single";

const SettingContainer = function ({children}) {
	return (
		<div className="rounded-lg bg-[#ffffff] mb-3 p-2 md:p-4">
			{children}
		</div>
	)
}

const SettingHeading = function ({text}) {
	return (
		<h4 className="mb-3 text-2xl font-bold">{text}</h4>
	)
}

const UpdateSuccess = function ({text}) {
	return (
		<div className="inline-flex rounded-lg items-center bg-green py-3 px-5 text-black block my-2">
			<p>{text}</p>
		</div>
	)
}

export const UpdatePassword = function ({userInfo}) {

	const [success, setSuccess] = useState(null);

	const dispatch = useDispatch()
	const form = useForm({
		mode: 'onBlur'
	});

	const { handleSubmit, reset, register, setError, formState, setValue, clearErrors } = form;
	const { errors } = formState;

	useEffect(() => {
		form.setValue('userid', userInfo.user_id);
	}, []);

	const submitForm = async (data) => {
		if (data.password !== data.confirmPassword) {
			setError('confirmPassword', {
				type: 'manual',
				message: 'Passwords do not match'
			});
			return;
		}

		if (data.password.length < 5) {
			setError('confirmPassword', {
				type: 'manual',
				message: 'Passwords must be at least 5 characters'
			});
			return;
		}
		
		const formData = prepareFormData(data); 
		
		try {
			const response = await dispatch(updateUserSetting({ type: 'password', formData })).unwrap();
			if (response.success === true) {
				setSuccess('success');
			}
		} catch (error) {
			setError("server", {
				type: "manual",
				message: error.message || "Failed to update password"
			});
		}
	};


	return (
		<>
			<SettingContainer>
				<SettingHeading text="Update Password" />

				{success && (
					<UpdateSuccess text="Successfully reset password" />
				)}

				<FormProvider {...form}>
					<form onSubmit={handleSubmit(submitForm)}>

						<PSInputText
							register={form.register}
							name="password"
							label="New Password"
							type="password"
							required
						/>

						<PSInputText
							register={form.register}
							name="confirmPassword"
							label="Confirm New Password"
							type="password"
							error={errors.confirmPassword?.message}
							required
						/>

						<PSSubmit 
							label='Update Password' 
						/>

					</form>
				</FormProvider>
			</SettingContainer>
		</>
	);
};

export const UpdateBio = function ({ userInfo }) {
	const [success, setSuccess] = useState(null);
	const dispatch = useDispatch();
	const form = useForm();

	const { handleSubmit, reset, register, setError, formState, setValue, clearErrors, control } = form;
	const { errors } = formState;

	useEffect(() => {
		if (userInfo) {
			setValue("bio", userInfo.bio || "");
			setValue("userid", userInfo.user_id);
		}
	}, [userInfo, setValue]);

	const submitForm = async (data) => {
		const formData = prepareFormData(data);

		try {
			const response = await dispatch(updateUserSetting({ type: "bio", formData })).unwrap();
			if (response.success === true) {
				setSuccess("success");
			}
		} catch (error) {
			setError("server", {
				type: "manual",
				message: error.message || "Failed to update bio",
			});
		}
	};

	const onChange = (val) => {
		console.log('val', val);
	}

	return (
		<>
			<SettingContainer>
				<SettingHeading text="Update Bio" />

				{success && <UpdateSuccess text="Successfully reset bio" />}

				<FormProvider {...form}>
					<form onSubmit={handleSubmit(submitForm)}>
						<PSWysiwyg
							name="bio"
							label="Bio"
							context="bio"
							control={control} // ✅ Pass control to PSWysiwyg
							elementId="bio-editor"
							onChange={onChange}
						/>
						<PSSubmit label="Update Bio" />
					</form>
				</FormProvider>
			</SettingContainer>
		</>
	);
};

export const UpdateUsername = function ({ userInfo }) {
	const [success, setSuccess] = useState(null);
	const [usernameExists, setUsernameExists] = useState(false);
	const dispatch = useDispatch();
	const form = useForm({ mode: 'onSubmit' }); // Only validate on submit

	const { handleSubmit, reset, register, setError, formState, setValue, clearErrors, getValues } = form;
	const { errors, isValid } = formState;

	// Set default values when userInfo is available
	useEffect(() => {
		if (userInfo) {
			setValue("username", userInfo.username || "");
			setValue("userid", userInfo.user_id);
		}
	}, [userInfo, setValue]);

	// Check if username is taken
	const checkUsername = async (username) => {
		if (!username) return false;
		
		try {
			const response = await fetch(`${process.env.REACT_APP_SERVER_URI}/api/users/checkUsername`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ username }),
			});
			const data = await response.json();
			
			if (data.msg === 'true') {
				setUsernameExists(true);
				setError('username', {
					type: 'manual',
					message: 'This username is already taken'
				});
				return true;
			} else {
				setUsernameExists(false);
				clearErrors('username');
				return false;
			}
		} catch (err) {
			console.error('Error checking username:', err);
			return false;
		}
	};

	const submitForm = async (data) => {
		// Run username validation inside submitForm
		const usernameTaken = await checkUsername(data.username);
		if (usernameTaken) return;

		const formData = prepareFormData(data);

		try {
			const response = await dispatch(updateUserSetting({ type: "username", formData })).unwrap();
			if (response.success === true) {
				setSuccess("success");
			}
		} catch (error) {
			setError("server", {
				type: "manual",
				message: error.message || "Failed to update username",
			});
		}
	};

	return (
		<>
			<SettingContainer>
				<SettingHeading text="Update Username" />

				{success && <UpdateSuccess text="Successfully reset username" />}

				<FormProvider {...form}>
					<form onSubmit={handleSubmit(submitForm)}>
						<PSInputText 
							register={register} 
							name="username" 
							label="Username" 
							type="text"
							error={errors.username?.message}
							required
						/>
						<PSSubmit label="Update Username" disabled={Object.keys(errors).length > 0 || !isValid} />
					</form>
				</FormProvider>
			</SettingContainer>
		</>
	);
};



export const UpdateEmail = function ({ userInfo }) {
	const [success, setSuccess] = useState(null);
	const [emailExists, setEmailExists] = useState(false);
	const dispatch = useDispatch();
	const form = useForm({ mode: 'onSubmit' });

	const { handleSubmit, reset, register, setError, formState, setValue, clearErrors, getValues } = form;
	const { errors, isValid } = formState;

	// Set default values when userInfo is available
	useEffect(() => {
		if (userInfo) {
			setValue("email", userInfo.email || "");
			setValue("userid", userInfo.user_id);
		}
	}, [userInfo, setValue]);

	// Check if username is taken
	const checkEmail = async (email) => {
		if (!email) return false;
		
		try {
			const response = await fetch(`${process.env.REACT_APP_SERVER_URI}/api/users/checkEmail`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email }),
			});
			const data = await response.json();
			
			if (data.msg === 'true') {
				setEmailExists(true);
				setError('email', {
					type: 'manual',
					message: 'This email is already taken'
				});
				return true;
			} else {
				setEmailExists(false);
				clearErrors('email');
				return false;
			}
		} catch (err) {
			console.error('Error checking email:', err);
			return false;
		}
	};

	const submitForm = async (data) => {
		// Run email validation inside submitForm
		const emailTaken = await checkEmail(data.username);
		if (emailTaken) return;

		const formData = prepareFormData(data);

		try {
			const response = await dispatch(updateUserSetting({ type: "email", formData })).unwrap();
			if (response.success === true) {
				setSuccess("success");
			}
		} catch (error) {
			setError("server", {
				type: "manual",
				message: error.message || "Failed to update email",
			});
		}
	};

	return (
		<>
			<SettingContainer>
				<SettingHeading text="Update Email Address" />

				{success && <UpdateSuccess text="Successfully reset email" />}

				<FormProvider {...form}>
					<form onSubmit={handleSubmit(submitForm)}>
						<PSInputText 
							register={register} 
							name="email" 
							label="Email Adderess" 
							type="email"
							error={errors.username?.message}
							required
						/>
						<PSSubmit label="Update Email" disabled={Object.keys(errors).length > 0 || !isValid} />
					</form>
				</FormProvider>
			</SettingContainer>
		</>
	);
};


export const UpdateName = function ({ userInfo }) {
	const [success, setSuccess] = useState(null);
	const dispatch = useDispatch();
	const form = useForm();

	const { handleSubmit, reset, register, setError, formState, setValue, clearErrors } = form;
	const { errors } = formState;

	// Set default values when userInfo is available
	useEffect(() => {
		if (userInfo) {
			setValue("firstName", userInfo.firstName || "");
			setValue("lastName", userInfo.lastName || "");
			setValue("userid", userInfo.user_id);
		}
	}, [userInfo, setValue]);

	const submitForm = async (data) => {
		const formData = prepareFormData(data);

		try {
			const response = await dispatch(updateUserSetting({ type: "name", formData })).unwrap();
			if (response.success === true) {
				setSuccess("success");
			}
		} catch (error) {
			setError("server", {
				type: "manual",
				message: error.message || "Failed to update name",
			});
		}
	};

	return (
		<>
			<SettingContainer>
				<SettingHeading text="Update Name" />

				{success && <UpdateSuccess text="Successfully reset name" />}

				<FormProvider {...form}>
					<form onSubmit={handleSubmit(submitForm)}>
						<PSInputText register={register} name="firstName" label="First Name" type="text" />
						<PSInputText register={register} name="lastName" label="Last Name" type="text" />

						<PSSubmit label="Update Name" />
					</form>
				</FormProvider>
			</SettingContainer>
		</>
	);
};


export const UpdateProfile = function ({ userInfo }) {
	const [success, setSuccess] = useState(null);
	const [imageSelected, setImageSelected] = useState(false); // Track valid image state
	const dispatch = useDispatch();
	const form = useForm();

	const { handleSubmit, reset, register, setError, formState, setValue } = form;
	const { errors } = formState;

	// Set default values when userInfo is available
	useEffect(() => {
		if (userInfo) {
			setValue("userid", userInfo.user_id);
		}
	}, [userInfo, setValue]);

	// Handle image validity change
	const handleImageChange = (image) => {
		// If image is valid, set imageSelected to true, else false
		if (image) {
			setImageSelected(true);
		} else {
			setImageSelected(false);
		}
	};

	const submitForm = async (data) => {
		if (!imageSelected) return; // Prevent form submission if image is invalid

		const formData = prepareFormData(data);

		try {
			const response = await dispatch(updateUserSettingImage({ type: "gallery", formData })).unwrap();
			if (response.success === true) {
				setSuccess("success");
			}
		} catch (error) {
			setError("server", {
				type: "manual",
				message: error.message || "Failed to update name",
			});
		}
	};

	return (
		<SettingContainer>
			<SettingHeading text="Update Profile Photo" />

			<Single
				className="relative w-[100px] h-auto aspect-[4/3.5]"
				image={userInfo?.gallery.length > 0 ? userInfo?.gallery[0] : null}
			/>

			{success && <UpdateSuccess text="Successfully reset Profile Photo" />}
			
			<FormProvider {...form}>
				<form onSubmit={handleSubmit(submitForm)}>
					<SingleImageUploader
						register={register}
						reset={reset}
						onImageChange={handleImageChange} // Pass callback to handle image change
					/>
					<PSSubmit label="Update Profile Photo" disabled={!imageSelected} /> {/* Disable if no valid image */}
				</form>
			</FormProvider>
		</SettingContainer>
	);
};


export const UpdateBanner = function ({ userInfo }) {
	const [success, setSuccess] = useState(null);
	const [imageSelected, setImageSelected] = useState(false); // Track valid image state
	const dispatch = useDispatch();
	const form = useForm();

	const { handleSubmit, reset, register, setError, formState, setValue } = form;
	const { errors } = formState;

	// Set default values when userInfo is available
	useEffect(() => {
		if (userInfo) {
			setValue("userid", userInfo.user_id);
		}
	}, [userInfo, setValue]);

	// Handle image validity change
	const handleImageChange = (image) => {
		// If image is valid, set imageSelected to true, else false
		if (image) {
			setImageSelected(true);
		} else {
			setImageSelected(false);
		}
	};

	const submitForm = async (data) => {
		if (!imageSelected) return; // Prevent form submission if image is invalid

		const formData = prepareFormData(data);

		try {
			const response = await dispatch(updateUserSettingImage({ type: "banners", formData })).unwrap();
			if (response.success === true) {
				setSuccess("success");
			}
		} catch (error) {
			setError("server", {
				type: "manual",
				message: error.message || "Failed to update banner image",
			});
		}
	};

	return (
		<SettingContainer>
			<SettingHeading text="Update Profile Banner Image" />

			<Single
				className="relative w-[100px] h-auto aspect-[4/3.5]"
				image={userInfo?.banners.length > 0 ? userInfo?.banners[0] : null}
			/>

			{success && <UpdateSuccess text="Successfully reset Profile Banner Image" />}
			
			<FormProvider {...form}>
				<form onSubmit={handleSubmit(submitForm)}>
					<SingleImageUploader
						register={register}
						reset={reset}
						onImageChange={handleImageChange} // Pass callback to handle image change
					/>
					<PSSubmit label="Update Profile Banner Image" disabled={!imageSelected} /> {/* Disable if no valid image */}
				</form>
			</FormProvider>
		</SettingContainer>
	);
};

