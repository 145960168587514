import { React } from "react"
import PSPage from "components/globals/ui/layout/Page"
import {
  PSCollapsableContainer,
  PSCollapsableContainerHeading,
  PSCollapsableContainerContent,
} from "components/globals/ui/PSCollapsableContainer";


const ChangelogContainer = function({startShown, version, date, children}) {
  return (
    <PSCollapsableContainer startShown={startShown}>
      <PSCollapsableContainerHeading>
        <div className="flex items-center py-3">
          <div className="inline-block bg-black text-white font-mono text-[14px] rounded-md">
            <span className="py-2 px-3 font-bold inline-block border-r-grey border-r-[1px]">
            {version}
            </span>
            <span className="inline-block py-2 pl-6 pr-7">
            {date}
            </span>
          </div>
        </div>
      </PSCollapsableContainerHeading>

      <PSCollapsableContainerContent>
        <div className="ors-rich-text mt-3">{children}</div>
      </PSCollapsableContainerContent>
    </PSCollapsableContainer>
  )
}

const Changelog = () => {
  return (
    <>
      <PSPage>
        <h1 className="text-3xl font-bold mb-small my-md">Open Road Society: Changelog</h1>

        <div className="mb-md">

          <ChangelogContainer startShown version="1.02" date="April 2nd, 2025">
            <p>Hotfixes</p>
            <ul>
              <li>added KIA images to the brands page</li>
              <li>Updated the posts to have custom year/make/model/color fields, so for example if you see a Delorean, and you don’t have one in your garage, you can set that information</li>
              <li>Custom car info added in a collapsable accordion with help text explaining what to do</li>
              <li>Associations of a post (garage/event/etc) are now in a collapsable accordion for better UX  </li>
            </ul>
          </ChangelogContainer>

          <ChangelogContainer startShown version="1.01" date="April 1st, 2025">
            <p>Hotfixes</p>
            <ul>
              <li>fixed the issue that was causing the blank pages.</li>
              <li>Handleizing photo upload names correctly now, so for example, uploading a photo with a space in the file name will no longer cause an error</li>
              <li>drag and drop to rearrange your photos now works on both desktop and mobile</li>
              <li>On car detail pages, more [brand] cars feed at the bottom now pulls in data</li>
              <li>user/car badges are now black with white text to make them more visible</li>
              <li>Ability to update profile picture and profile banner on the settings page</li>
              <li>Fixed instances where the uploaded profile photo wouldn't work</li>
              <li>Fixed some instances where reloading the page in a new browser/tab would log you out</li>
              <li>Home page carousel image now supports title, description, and buttons</li>
              <li>Added new panel when the large red plus icon is clicked in the header, which opens up a pane of new posting options</li>
              <li>Better image validation on the profile and banner images (can't upload HEIC, only let you pass when a valid image is selected)</li>
            </ul>
          </ChangelogContainer>
          
          <ChangelogContainer version="1.0" date="April 1st, 2025">
            <ul>
              <li>Launched Version 1 of the site.</li>
            </ul>
          </ChangelogContainer>

        </div>
      </PSPage>
    </>
    
  );
};

export default Changelog;
